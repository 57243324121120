<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">{{ i18n('slider.title') }}</h2>
    </div>

    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div
        class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
      >
        <div class="flex mt-5 sm:mt-0">
          <button
            class="btn bg-theme-31 text-white sm:w-auto py-2"
            @click="$router.push('/slider/new')"
          >
            <PlusIcon class="w-5 h-5 mr-2 ml-2" /> {{ i18n('common.create') }}
          </button>
        </div>
      </div>
      <div ref="table" class="relative">
        <loading-spinner
          type="half-circle"
          :duration="1000"
          :size="40"
          color="#000"
          :loading="loading"
        />
        <div class="overflow-x-auto">
          <table class="table table-report sm:mt-2">
            <thead>
              <tr>
                <th class="whitespace-nowrap text-center">#</th>
                <th
                  v-for="col in columns"
                  :key="col.name"
                  :id="col.name === 'action' ? 'ignore-1' : ''"
                  class="text-center w-20 whitespace-nowrap"
                >
                  {{ i18n(col.label) }}
                </th>
              </tr>
            </thead>
            <tbody v-if="rows.length">
              <tr v-for="(row, index) in rows" :key="index" class="intro-x">
                <td class="w-40 text-center">
                  <div class="text-gray-600 text-xs whitespace-nowarp mt-0 5">
                    {{ index + 1 }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterLocalization(row, 'title') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{
                      truncateString(presenterLocalization(row, 'description'))
                    }}
                  </div>
                </td>
                <td class="flex items-center justify-center">
                  <div class="font-medium whitespace-nowrap text-gray-600">
                    <div class="flex-none rounded-md overflow-hidden w-64">
                      <img
                        :src="isRTL ? row.imageAr : row.imageEn"
                        alt=""
                        data-action="zoom"
                      />
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterDate(row, 'createdAt') }}
                  </div>
                </td>
                <td class="table-report__action w-60">
                  <div class="flex justify-center items-center gap-4">
                    <router-link
                      class="text-edit dark:text-theme-30 flex items-center gap-1 whitespace-nowrap"
                      :to="`/slider/${row.id}/edit`"
                    >
                      <EditIcon class="w-4 h-4" />
                      {{ i18n('common.edit') }}
                    </router-link>
                    <router-link
                      class="text-view dark:text-theme-30 flex items-center gap-1 whitespace-nowrap"
                      :to="`/slider/${row.id}`"
                    >
                      <EyeIcon class="w-4 h-4" />{{ i18n('common.view') }}
                    </router-link>
                    <a
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#delete-modal-preview"
                      @click="selectedId = row.id"
                      class="text-theme-24 flex items-center gap-1 whitespace-nowrap"
                    >
                      <Trash2Icon class="w-4 h-4" /> {{ i18n('common.delete') }}
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-empty-page
            v-if="!rows.length && !loading"
            :label="i18n('empty.slider')"
            label-class="text-lg font-medium"
            icon-size="4x"
            class="box w-full py-64"
          ></app-empty-page>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <!-- BEGIN: Delete Modal -->
    <DeleteModal item="slider" @delete="doDestroy(selectedId)" />
    <!-- END: Delete Modal -->
  </div>
</template>

<script>
import DeleteModal from '@/components/modals/delete-modal.vue'
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default defineComponent({
  components: {
    DeleteModal
  },
  data() {
    return {
      selectedId: null,
      columns: [
        {
          name: 'title',
          field: 'title',
          label: 'slider.fields.title',
          align: 'center'
          // required: true,
        },
        {
          name: 'description',
          field: 'description',
          label: 'slider.fields.description',
          align: 'center'
          // required: true,
        },
        {
          name: 'image',
          field: 'image',
          label: 'slider.fields.image',
          align: 'center'
          // required: true,
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'slider.fields.createdAt',
          align: 'center'
          // required: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'slider/list/rows',
      pagination: 'slider/list/pagination',
      listLoading: 'slider/list/loading',
      destroyLoading: 'slider/destroy/loading'
    }),
    loading() {
      return this.listLoading || this.destroyLoading
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.slider')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.slider')
    }
  },
  async mounted() {
    await this.doFetch()
  },
  methods: {
    ...mapActions({
      doFetch: 'slider/list/doFetch',
      doDestroy: 'slider/destroy/doDestroy'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    truncateString(str) {
      return str.length > 100 ? str.slice(0, 100) + '...' : str
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      return GenericModel.presenterDecimal(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    }
  }
})
</script>

<style lang="scss">
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.delete-icon {
  position: absolute;
  width: 80px;
  cursor: pointer;
  margin-top: 200px;
  margin-left: 10px;

  @media only screen and (max-width: 1440px) {
    width: 80px;
    margin-top: 120px;
  }

  @media only screen and (max-width: 1024px) {
    width: 80px;
    margin-top: 80px;
  }

  @media only screen and (max-width: 768px) {
    width: 80px;
    margin-top: 120px;
  }

  @media only screen and (max-width: 425px) {
    width: 80px;
    margin-top: 60px;
  }

  @media only screen and (max-width: 375px) {
    width: 60px;
    margin-top: 60px;
  }

  @media only screen and (max-width: 320px) {
    width: 60px;
    margin-top: 40px;
  }

  @media only screen and (max-width: 300px) {
    width: 60px;
    margin-top: 20px;
  }
}

[dir='rtl'] .delete-icon {
  margin-left: unset !important;
  margin-right: 10px !important;
}
</style>
